//<im-upload mytitle="Upload My Own Photo" calltoaction="Start Your Project" browsebuttontext=""></im-upload>


//Attributes:
//postpage
//calltoaction
//title
//active
//sendonfileload

//Events:
//upload   user clicked upload
//gotfile  user choose file






//<im-upload mytitle="Upload My Own Photo" calltoaction="Start Your Project" postpage="im.aspx"></im-upload>

(function () {
    let MAXUPLOADIMAGESIZE = 1500;

    function useLizard(b64, parentElement, wait) {
        let $ = parentElement;
        let siteid = window.IMWC.getSiteID();
        if (siteid == null || siteid == '') {
            console.error('im-upload', 'abort : SiteID is not configured');
            return;
        }
        let useAutoMapSurfaceTypes = $.getAttribute('useAutoMapSurfaceTypes');   //"roof,wall,walls";
        let useAutoMapContext = $.getAttribute('useAutoMapContext');   //Interior, Exterior
        let serviceType = $.getAttribute('serviceType');   //interior,ExteriorRoofSiding,ExteriorAll

        if (useAutoMapSurfaceTypes == null || useAutoMapSurfaceTypes == '' || useAutoMapContext == null || useAutoMapContext == '' || serviceType == null || serviceType == '') {
            console.error('im-upload', 'abort : configuration error');
            return;
        }

        let baseSpec = window.IMWC.getSiteID() + "_USER_";
        let projectname = "";

       
        IMWC.resizeBase64Image(b64, MAXUPLOADIMAGESIZE).then(function (resized) {
            console.log('resized', resized);
            //send
            let r = window.IMWC.lizard.submitToAutoIM(resized, projectname, baseSpec, useAutoMapSurfaceTypes, useAutoMapContext, serviceType);
            r.then(function (response) {
                let data = response.clone().json();
                //console.log('data', b64);
                data.then(function (dta) {
                    //{Specifier: "...", Sel: "..."}
                    if (dta.success == false) {
                        window.IMWC.triggerEvent(parentElement, 'error', dta);
                        if (wait) wait.setAttribute('active', '0');
                        return;
                    }

                    window.setTimeout(function () {
                        window.IMWC.triggerEvent(parentElement, 'gotspecifier', dta);
                        if (wait) wait.setAttribute('active', '0');
                        if (window.ChamStats != void 0) window.ChamStats.sendData('upload', dta.specifier);
                        var postpage = $.getAttribute('postpage') || 'im.aspx';
                        var loc = window.location;
                        window.location = loc.protocol + '//' + loc.hostname + (loc.port ? ':' + loc.port : '') + '/' + postpage + '?specifier=' + encodeURIComponent(dta.specifier);
                    }, 1000);
                
                });

            }).catch(function (error) {
                window.IMWC.triggerEvent(parentElement, 'error', { "error": error });
                console.error('Error:', error);
            });

        });






        
    }







    class myClass extends HTMLElement {
        constructor() {
            super();            
            this.shadow = this;
            //this.shadow = this.attachShadow({ mode: 'open' });
            this.head = document.head;
            IMWC.embedIMCss();
            this.css();
            //events, attach to context
            var $ = this;
            function find(selector) {
                var el = $.querySelector(selector);
                return (el) ? el : document.createElement('div'); // fail silent
            }
            function show(selector) {
                var el = $.querySelector(selector);
                if (el) el.style.display = 'block';
            }
            function hide(selector) {
                var el = $.querySelector(selector);
                if (el) el.style.display = 'none';
            }
            function verifyUploadImg(fileUpload) {
                var validImgTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/bmp'];
                for (var i = 0; i < validImgTypes.length; i++) {
                    if (fileUpload.src.includes(validImgTypes[i])) {
                        return true;
                    }
                }
                return false;
            }
            var hasClass = IMWC.hasClass;




            $.addEventListener("click", function (event) {
                var el = event.target;
                if (el) {
                    
                    if (el.getAttribute('data-csaction') === 'upload') {
                        window.IMWC.triggerEvent($, 'upload', null);
                        
                        var uploadImg = find('div.modal-pic img');
                        //verify that an image has been selected
                        if (uploadImg.getAttribute('src') === '') {
                            find('div.upload-box span').innerHTML = 'To continue, choose an image from your computer to upload.';
                            if (find('div.upload-box span').style.display == 'none') { show('div.upload-box span'); }
                            return;
                        }
                        //console.log('im-upload2');
                        //verifies that image is valid file type
                        if (!verifyUploadImg(uploadImg)) {
                            find('div.upload-box span').innerHTML = 'You must upload a JPG, PNG, BMP, or GIF to continue.';
                            find('div.modal-pic img').src = '';
                            show('div.im-upload');
                            if (find('div.upload-box span').style.display == 'none') { show('div.upload-box span'); }
                            return;
                        } else hide('div.upload-box span');
                        
                        
                        var crop = document.querySelector('im-crop');
                        if (crop) {
                            var myimg = $.querySelector('div.modal-pic img');
                            var canvas = document.createElement('canvas');
                            canvas.width = myimg.width;
                            canvas.height = myimg.height;
                            var ctx = canvas.getContext('2d');
                            ctx.drawImage(myimg, 0, 0, canvas.width, canvas.height);
                            crop.setAttribute('src', canvas.toDataURL('image/jpeg', 1.0));
                            canvas = ctx = null;
                            crop.setAttribute('active', '1');
                            $.setAttribute('active', '0');
                            return;
                        }
                        
                        $.setAttribute('active', '0');
                        //var wait = document.querySelector('im-wait');
                        //if (wait) wait.classList.add("active");

                        $.sendImage();

                    }
                    else if (hasClass(el, 'modal-close')) {
                        $.setAttribute('active', '0');
                    }
                    else if (hasClass(el, 'trash')) {
                        find('div.modal-pic img').src = '';
                        find('input.fileUploader').value = '';
                        show('div.im-upload');
                        show('.upload-box');
                        //hide('.modal-pic');
                    }
                    }
                
                
                
            }, false);

            $.addEventListener("change", function (event) {
                var el = event.target;
                if (el) {
                    if (el.classList.contains('fileUploader')) {
                        if (el.files && el.files[0]) {
                            find('div.modal-pic img').onload = function () { };
                            var reader = new FileReader();
                            reader.onload = function (e) {
                                window.IM3DropedFile = null;
                                var img = find('div.modal-pic img');
                                if (img) {
                                    img.setAttribute('src', e.target.result);
                                    show('.modal-pic');
                                    hide('.upload-box');
                                    window.IMWC.triggerEvent($, 'gotfile', null);
                                    if ($.getAttribute('sendonfileload') == '1') {
                                        var wait = document.querySelector('im-wait');
                                        if (wait) wait.setAttribute('active', '1'); 
                                        $.sendImage();
                                    }
                                }
                            };
                            reader.readAsDataURL(el.files[0]);
                            //$('#txtFileName').val(this.files[0].name);
                        } else {
                            //?IE9
                            /*
                            $('.upload-box input').hide();
                            $('.upload-box label.uploaderBTN').show();
                            $('.upload-box').show();
                            $('div.upload-box span').show();
                            */
                        }
                    }
                }
            }, false);



        }






        sendImage(imgSrc, projectName) {
            var $ = this;
            function find(selector) {
                var el = $.querySelector(selector);
                return (el) ? el : document.createElement('div'); // fail silent
            }
            projectName = projectName || 'my project';
            var wait = document.querySelector('im-wait');
            if (wait) wait.setAttribute('active', '1');


            let serviceType = $.getAttribute('serviceType');
            if (serviceType) {
                if (imgSrc) useLizard(imgSrc, $, wait);
                else {
                    var myimg = find('div.modal-pic img').src;
                    useLizard(myimg, $, wait);
                }   
                return;
            }
            



            var frm = document.createElement('form');
            frm.style.display = 'none';
            frm.enctype = "multipart/form-data";
            var name = document.createElement('input');
            name.name = 'projectname';
            var elTxt = $.querySelector('input.name');
            if (elTxt) name.value = elTxt.value;
            else name.value = projectName;
            frm.appendChild(name);
            if (imgSrc === void 0) {
                var input = document.createElement('textarea');
                input.name = 'img';
                input.maxLength = 175000000;
                input.size = 175000000;
                //input.max = 17500000;
                
                input.value = imgSrc || find('div.modal-pic img').src;
                input.style.dispaly = 'none';
                frm.appendChild(input);
            }
            else if ((window.IM3DropedFile === null || window.IM3DropedFile === void 0) && imgSrc === void 0) {
                var _input = find('input.fileUploader');
                    frm.appendChild(_input);
             
            } else {
                var input = document.createElement('textarea');
                input.name = 'img';
                input.maxLength = 175000000;
                input.size = 175000000;
                //input.max = 17500000;
                
                input.value = imgSrc || find('div.modal-pic img').src;
                input.style.dispaly = 'none';
                frm.appendChild(input);
            }

            //console.log('im-upload  input.value=', input.value);
            console.log('Before IMWC.resizeBase64Image', input.value.length);
            IMWC.resizeBase64Image(input.value, MAXUPLOADIMAGESIZE).then(function(data){
                input.value = data;
                console.log('IMWC.resizeBase64Image', input.value.length);
                let _siteid = IMWC.getSiteID() || 'demo';
                var baseSpec = _siteid + '_USER_';
                var input1 = document.createElement('textarea');
                input1.name = 'baseSpec';
                input1.value = baseSpec;
                input1.style.dispaly = 'none';
                frm.appendChild(input1);
                frm.method = 'POST';
                var postpage = $.getAttribute('postpage') || 'im.aspx';
                frm.action = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/' + postpage;
                document.getElementsByTagName('body')[0].appendChild(frm);
                frm.submit();
                

                //if (window.ChamStats != void 0) window.ChamStats.sendData('upload', baseSpec);   baseSpec is not complete

            }); 





        }


        css() {
                var scss = `im-upload #upload-section {
  display: block;
}
im-upload #upload-section > div {
  position: relative;
  padding: 20px;
}
im-upload #upload-section > div .modal-headline {
  margin: 0;
  text-transform: uppercase;
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  color: #353535;
  letter-spacing: 3px;
}
im-upload #upload-section > div .modal-close {
  float: right;
  color: black;
  margin-right: 0px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
  width: 25px;
}
im-upload #upload-section > div .modal-close .times img {
  height: 100%;
  width: 100%;
}
im-upload #upload-section > div .modal-box {
  width: 100%;
  height: auto;
  margin: 2vmin auto;
  text-align: center;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  max-height: 460px;
}
im-upload #upload-section > div .modal-box img {
  width: 100%;
}
im-upload #upload-section > div .modal-box .upload-box {
  width: 100%;
  height: auto;
  margin: 0;
  background-color: #f3f3f3;
  border: dashed 2px #353535;
  color: #65656a;
  left: 0;
  right: 0;
  z-index: 300;
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: absolute;
  height: 100%;
}
im-upload #upload-section > div .modal-box .upload-box p {
  top: 55%;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
}
im-upload #upload-section > div .modal-box .upload-box .uploaderBTN {
  color: #353535;
  text-align: center;
  cursor: pointer;
  border-radius: 1px;
  box-sizing: border-box;
  padding: 15px 30px;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  border: solid 1px #868686;
  letter-spacing: 3px;
  top: 40%;
  position: absolute;
  right: 0;
  left: 0;
  width: 200px;
  margin: 0 auto;
}
im-upload #upload-section > div .modal-box .upload-box input#imgInp {
  display: none;
}
im-upload #upload-section > div .modal-box .upload-box span {
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  top: 65%;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: none;
  color: red;
}
im-upload #upload-section > div .modal-box .modal-pic {
  background-color: #EAEAEA;
  z-index: 200;
  width: 100%;
  height: auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  overflow: auto !important;
  height: 600px;
}
im-upload #upload-section > div .modal-box .modal-pic .trash {
  background-color: #828282;
  color: #FFF;
  font-size: 4vmin;
  cursor: pointer;
  border-top-right-radius: 3px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 200;
  padding: 1vmin;
  font-size: 36px;
}
im-upload #upload-section > div .modal-box .modal-pic .trash #imgToUpload {
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
im-upload #upload-section > div .upload-box.highlight {
  background-color: #c6c6c6;
}
im-upload #upload-section .project-button.btn-upload.browse {
  padding: 15px 30px;
  background-color: #8599A0;
  border: 2px solid #8599A0;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 0 auto;
  color: white;
  letter-spacing: 0.05em;
}
im-upload #upload-section .project-button.btn-upload.browse:hover {
  background-color: #70868E;
}`;


            IMWC.embedCss(this, scss);
        }

        
        //setup html
        draw(context) {
            context.innerHTML = `
<div id="upload-section"  class="lightbox ghost-center">
<div>
                <div class="modal-close" role="button" aria-label="Close dialog">
                    <div class="times">
                        <img src="/img/close.svg">
                    </div>
                </div>
                <h1 class="modal-headline">
                    Upload My Own Photo
                </h1>
                <div class="modal-box">
                    <div class="upload-box">

                            <input type="file" id="imgInp" class="fileUploader" role="button" aria-label="Upload an image">

                        <label for="imgInp" class="uploaderBTN">Browse</label>
                        
                     
                        <p>or drag an image into this area</p>
                      

                        <span>To continue, choose an image from your computer to upload.</span>
                    </div>
                    <div class="modal-pic">
                        <div class="trash" aria-label="Trash" role="button"><i class="fas fa-trash-alt"></i></div>
                        <img id="imgToUpload" src="">
                    </div>
                </div>
                <div class="project-button btn-upload browse" data-csaction="upload" role="button" aria-label="Start Your Project">
                    Start Your Project 
                </div>
  </div>
            </div>
`;
        }

        connectedCallback() {
            var $ = this;
            $.classList.add("im-component");
            //this.classList.add("hide");  //default status

            var context = document.createElement('div');
            context.classList.add("im-context");
            $.draw(context);           
            $.shadow.appendChild(context);

            //check attributes
            (function () {
                var arr = myClass.observedAttributes;
                for (var i = (arr.length - 1) | 0; i > -1; i = (i - 1) | 0) {
                    var tmpattr = $.getAttribute(arr[i]);
                    if (tmpattr != null) $.useAttribute(arr[i], tmpattr);
                }
            }());


            //dragging
            (function () {
                var dropArea = $.querySelector('div.upload-box');                
                if (!dropArea) return;

                ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                    dropArea.addEventListener(eventName, function preventDefaults(e) {
                        e.preventDefault();
                        e.stopPropagation();
                    }, false);
                });

                ['dragenter', 'dragover'].forEach(eventName => {
                    dropArea.addEventListener(eventName, function highlight(e) {
                        dropArea.classList.add('highlight');
                    }, false);
                });

                ['dragleave', 'drop'].forEach(eventName => {
                    dropArea.addEventListener(eventName, function unhighlight(e) {
                        dropArea.classList.remove('highlight');
                    }, false);
                });

                dropArea.addEventListener('drop', function handleDrop(e) {
                    if (e.dataTransfer.files === void 0) return;
                    var file = e.dataTransfer.files[0], reader = new FileReader();
                    reader.onload = function (event) {
                        window.IM3DropedFile = true;
                        var img = $.querySelector('div.modal-pic img');
                        if (img) {
                            img.setAttribute('src', event.target.result);
                            var el = $.querySelector('.modal-pic');
                            if(el) el.style.display = 'block';
                            el = $.querySelector('.upload-box');
                            if (el) el.style.display = 'none';
                        }
                    };
                    reader.readAsDataURL(file);
                    return false;

                    ////////////////////handleFiles(files);
                }, false);

            }());

        }

        disconnectedCallback() {
        }

        static get observedAttributes() { return ['mytitle', 'calltoaction', 'active', 'browsebuttontext']; }

        attributeChangedCallback(name, oldValue, newValue) {
            this.useAttribute(name, newValue);         
        }

        useAttribute(name, newValue) {
            var el;
            if (name === 'mytitle') {
                el = this.shadow.querySelector('h1');
                if (el) el.innerHTML = newValue;
            }
            else if (name === 'calltoaction') {
                el = this.shadow.querySelector('[data-csaction="upload"]');
                if (el) {
                    el.innerHTML = newValue;
                    el.setAttribute('aria-label', newValue);
                }
            }
            else if (name === 'active') {
                if (newValue === '1') this.classList.add("active");
                else this.classList.remove("active");
            }
            else if (name === 'browsebuttontext') {
                if (newValue == '') return;
                el = this.shadow.querySelector('label.uploaderBTN');
                if (el) {
                    el.innerHTML = newValue;
                }
            }
        }

       

    }


    if (!window.customElements.get('im-upload')) window.customElements.define('im-upload', myClass); 
}());
