//<im-preview mytitle="Preview" active="1" editbuttontext="Edit" continuebuttontext="Continue"></im-preview>
/*Events
    'previewedit'
    'previewcontinue'
*/
//TODO:  only call for image if visiable

(function () {

    class myClass extends HTMLElement {
        constructor() {
            super();
            this.shadow = this;
            //this.shadow = this.attachShadow({ mode: 'open' });
            this.head = document.head;
            IMWC.embedIMCss();
            this.css();
            //events, attach to context
            var $ = this;
            function sendEvent(name) {
                var new_event = new CustomEvent(name, {
                    bubbles: true,
                    cancelable: false
                });
                $.dispatchEvent(new_event);
            }
            $.addEventListener("click", function (event) {
                var el = event.target;
                if (el) {
                    if (el.getAttribute('data-csaction') === 'preview-edit') {
                        $.setAttribute('active', '0');
                        sendEvent("previewedit");
                    }
                    else if (el.getAttribute('data-csaction') === 'preview-viz') {
                        $.setAttribute('active', '0');
                        sendEvent("previewcontinue");
                    }
                }


            }, false);

        }


        css() {
                var scss = `im-preview #im-modal > div {
  padding: 20px;
}
im-preview #im-modal h2.title {
  line-height: 36px;
  margin: 20px 0;
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  color: #353535;
  letter-spacing: 3px;
  text-transform: uppercase;
}
im-preview #im-modal .confirm-btns {
  display: -ms-flexbox;
  display: flex;
  margin: 20px;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
im-preview #im-modal .confirm-btns .im-modal-edit, im-preview #im-modal .confirm-btns .im-modal-continue {
  color: white;
  text-transform: uppercase;
  background-color: #8599A0;
  border: none;
  display: inline-block;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 15px 25px;
  font-size: 12px;
  margin: 0 5px 5px;
  min-width: 240px;
  text-align: center;
}
im-preview #im-modal .confirm-btns .im-modal-edit:hover, im-preview #im-modal .confirm-btns .im-modal-continue:hover {
  background-color: #70868E;
}

.im-component .lightbox {
  display: block !important;
}`;


            IMWC.embedCss(this, scss);
        }
        

        //setup html
        draw(context) {
            let $ = this;

            context.innerHTML = `<div id="im-modal" class="lightbox ghost-center">
<div>
                <div class="im-modal-content" >
                    <h2 class="title"></h2>
                    <div class="preview-img"><img src=""></div>
                        <div class="confirm-btns">
                            <div class="im-modal-edit" data-csaction="preview-edit">Edit Image</div>
                            <div class="im-modal-continue" data-csaction="preview-viz">Continue</div>
                        </div>
                </div>
</div>
    </div >`;
        }

        connectedCallback() {
            var $ = this;
            this.classList.add("im-component");
            

            var context = document.createElement('div');
            context.classList.add("im-context");
            this.draw(context);
            //console.log('this2', this.shadow);

            this.shadow.appendChild(context);

            var parm = $.getAttribute('specifier');
            if (!parm) {
                function getURLParameter(name) { return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)', 'i').exec(location.search.toLowerCase()) || [, ""])[1].replace(/\+/g, '%20')) || null; };
                parm = getURLParameter('specifier');
                if (parm) $.setAttribute('specifier', parm);
            }

            //check attributes
            (function () {
                var arr = myClass.observedAttributes;
                for (var i = (arr.length - 1) | 0; i > -1; i = (i - 1) | 0) {                    
                    var tmpattr = $.getAttribute(arr[i]);
                    if (tmpattr != null) $.useAttribute(arr[i], tmpattr);
                }
            }()); 

            


            //todo
            //check attr active
            //handle arrt change
            //check query string

        }

        disconnectedCallback() {
        }

        makeURL(spec) {
            function toApply(maxSurface) {
                maxSurface++;
                function toColor(num) {
                    num >>>= 0;
                    var b = num & 0xFF
                        , g = (num & 0xFF00) >>> 8
                        , r = (num & 0xFF0000) >>> 16
                    //,a = ( (num & 0xFF000000) >>> 24 ) / 255 ;
                    return "RGB(" + [r, g, b].join(",") + ")";
                }
                var out = '(apply=(';
                while (maxSurface--) {
                    out += toColor(Math.pow(10, maxSurface));
                }
                return out + '))';

            }

            var src = IMWC.renderEngine || (window.CHAM || {}).RenderServer || '//core2render.chameleonpower.com/cham/';
            src += 'GetImage.ashx?Size=800&type=base&spec=';
            //['0,255,0', '255,0,0', '0,0,255'].map(function(item){return 'COLOR(RGB(' + item + '))'}).join(",");
            src += spec.replace('()', '') + toApply(3);
            console.log(src);
            return src;
        }


        useAttribute(name, newValue) {
            var el;
            if (name === 'mytitle') {
                el = this.shadow.querySelector('.title');
                if (el) el.innerHTML = newValue;
            }
            else if (name === 'specifier') {
                if (this.specifier == newValue) return;
                el = this.shadow.querySelector('img');
                if (el) {
                    el.src = this.makeURL(newValue);
                }
                this.specifier = newValue;
            }
            else if (name === 'active') {
                if (newValue === '1') {                    
                    this.classList.add("active");
                }
                else this.classList.remove("active");
            }
            else if (name === 'editbuttontext') {
                el = this.shadow.querySelector('div.im-modal-edit');
                if (el) el.innerHTML = newValue;
            }
            else if (name === 'continuebuttontext') {
                el = this.shadow.querySelector('div.im-modal-continue');
                if (el) el.innerHTML = newValue;
            }

        }

        static get observedAttributes() {
            return ['mytitle', 'specifier', 'active', 'editbuttontext','continuebuttontext']; }

        attributeChangedCallback(name, oldValue, newValue) {
            this.useAttribute(name, newValue);
        }




    }


    if (!window.customElements.get('im-preview')) window.customElements.define('im-preview', myClass);
}());
