//<im-prelude-wrapper></im-prelude-wrapper>

(function () {

    class myClass extends HTMLElement {
        constructor() {
            super();
            this.shadow = this;
            //this.shadow = this.attachShadow({ mode: 'open' });
        }



        connectedCallback() {
            var $ = this;
            $.classList.add("im-component");

            var context = document.createElement('div');
            context.classList.add("im-context");
            //context.innerHTML = `<div></div>`;
            $.shadow.appendChild(context);

            //check attributes
            (function () {
                var arr = myClass.observedAttributes;
                for (var i = (arr.length - 1) | 0; i > -1; i = (i - 1) | 0) {
                    var tmpattr = $.getAttribute(arr[i]);
                    if (tmpattr != null) $.useAttribute(arr[i], tmpattr);
                }
            }());


            function getURLParameter(name) { return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)', 'i').exec(location.search.toLowerCase()) || [, ""])[1].replace(/\+/g, '%20')) || null; };
            var parm = getURLParameter('specifier');
            if (!parm) {
                $.setAttribute('active', '1');
                var nodes = $.querySelectorAll('.im-component');
                if (nodes.length > 0) nodes[0].setAttribute('active', '1');
            }
            else $.setAttribute('active', '0');





        }

        disconnectedCallback() {
        }

        static get observedAttributes() { return ['active']; }

        attributeChangedCallback(name, oldValue, newValue) {
            this.useAttribute(name, newValue);
        }

        useAttribute(name, newValue) {
            if (name === 'active') {
                if (newValue === '1') this.classList.add("active");
                else this.classList.remove("active");
            }
        }




    }


    if (!window.customElements.get('im-prelude-wrapper')) window.customElements.define('im-prelude-wrapper', myClass);
}());
