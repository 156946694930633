//<im-upload-button>Upload</im-upload-button>
/*
Uses AutoIM.ashx service


//gotfile



*/


(function () {

    var myCustomTagName = 'im-upload-button';


    function SetupEvents(parentElement) {
        var eventsToDistroy = [];  //{el, eventname, fn}
        function addEvent(el, evname, fn) {
            if (!el) return;
            el.addEventListener(evname, fn);
            eventsToDistroy.push({ el: el, eventname: evname, fn: fn });
        }

        var input = parentElement.querySelector('input.fileUploader');

        addEvent(parentElement, 'click', function () {
            if (input) input.click();
        });

        addEvent(input, 'change', function () {
            var el = event.target;
            if (el.files && el.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    window.IMWC.triggerEvent(parentElement, 'gotfile', e.target.result);
                    
                    var wait = document.querySelector('im-wait');
                    if (wait) wait.setAttribute('active', '1');
                    // $.sendImage();
                    var b64 = e.target.result    //is the file data b64
                    window.IMWC.SubmitToAutoIM(b64, '', null, null).then(function (response) {
                        let data = response.clone().json();
                        console.log('data', data);
                        data.then(function (dta) {
                            //{Specifier: "...", Sel: "..."}
                            window.IMWC.triggerEvent(parentElement, 'gotspecifier', dta);
                        });

                    });




                };
                reader.readAsDataURL(el.files[0]);
            }
        });



        return eventsToDistroy;
    }


    class myClass extends HTMLElement {
        constructor() {
            super();
            this.shadow = this;
            this.head = document.head;
            //this.shadow = this.attachShadow({ mode: 'open' });
        }
        
        connectedCallback() {
            var $ = this;
            $.style.cursor = 'pointer';
            //draw ?
            var input = $.querySelector('input.fileUploader');
            if (!input) {
                input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.classList.add("fileUploader");
                input.style.visibility = 'hidden';
                input.style.position = 'absolute';
                input.style.top = '0';
                input.style.left = '-5000px';
            }
            $.append(input);

            $.eventsToDistroy = SetupEvents($);


        }

        disconnectedCallback() {
            var $ = this;
            if ($.eventsToDistroy) {
                var i = null;
                while (i = $.eventsToDistroy.shift()) i.el.removeEventListener(i.eventname, i.fn, false);
            }
            var x = $.querySelectorAll('*');
            if (x.length > 0) { x[0].parentNode.removeChild(x[0]); }
        }

    }


    if (!window.customElements.get(myCustomTagName)) window.customElements.define(myCustomTagName, myClass);
}());
