//<im-intro mytitle="Image Mapper" calltoaction="GO"></im-intro>

(function () {

    class myClass extends HTMLElement {
        constructor() {
            super();            
            this.shadow = this;
            this.head = document.head;
            //this.shadow = this.attachShadow({ mode: 'open' });
            IMWC.embedIMCss();
            this.css();
            

            var hasClass = IMWC.hasClass;

            //events, attach to context
            var $ = this;
            $.addEventListener("click", function (event) {
                var el = event.target;
                if (el) {
                    if (el.getAttribute('data-csaction') === 'openupload') {
                        var upload = document.querySelector('im-upload');
                        if (upload) {
                            upload.setAttribute('active', '1');
                            $.setAttribute('active', '0');
                        }
                    }
                    else if (hasClass(el,'modal-close')) {
                        $.setAttribute('active', '0');
                    }
                }
                
                
            }, false);
        }




        css() {

            var scss = `im-intro aside#im-modal {
  display: block;
}
im-intro aside#im-modal > div {
  padding: 20px;
}
im-intro aside#im-modal > div .header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  padding: 10px;
  margin-bottom: 20px;
  position: relative;
}
im-intro aside#im-modal > div .header h1 {
  margin: 0;
  text-transform: uppercase;
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  color: #353535;
  letter-spacing: 3px;
}
im-intro aside#im-modal > div .header button.modal-close {
  width: 25px;
  height: 25px;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  right: 0;
  top: 0;
  position: absolute;
}
im-intro aside#im-modal > div .header button.modal-close img {
  width: 100%;
}
im-intro aside#im-modal > div h4 {
  text-align: center;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 13px;
  padding: 0 5%;
}
im-intro aside#im-modal > div ul {
  border-top: solid 0.01em #cecece;
  border-bottom: solid 0.01em #cecece;
  padding: 10px 20px;
  margin-bottom: 20px;
  list-style: none;
}
im-intro aside#im-modal > div li {
  font-weight: 400;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  text-align: left;
  letter-spacing: 1px;
  font-size: 13px;
}
im-intro aside#im-modal > div li i {
  color: #8599A0;
  margin-right: 5px;
}
im-intro .im-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-align: center;
  align-items: center;
}
im-intro .im-body span {
  color: #8599A0;
  text-align: center;
  font-size: 0.87em;
  padding: 0 5%;
}
im-intro .process {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
}
im-intro .process img {
  width: 100%;
  max-width: 600px;
}
im-intro .im-content {
  padding: 0 50px;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
im-intro .im-content .im-step {
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-align: center;
  align-items: center;
}
im-intro .im-content .im-step img {
  width: 100%;
  max-width: 300px;
  padding: 5px;
  border: solid 1px #8599A0;
}
im-intro .im-content .im-step p {
  margin: 10px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
}
im-intro button#upload-banner {
  padding: 15px 30px;
  background-color: #8599A0;
  border: 2px solid #8599A0;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 0 auto;
  letter-spacing: 0.05em;
  min-width: 250px;
  font-weight: 700;
}
im-intro button#upload-banner:hover {
  background-color: #70868E;
}
im-intro button {
  outline: none !important;
}`;



            IMWC.embedCss(this, scss);
        }




        
        
        //setup html
        draw(context) {
            context.innerHTML = `
<aside id="im-modal" class="lightbox ghost-center">
        <div>
            <div class="header">
                <button class="modal-close" type="button" aria-label="Close dialog">
                    <img src="/img/close.svg"></button>
                <h1 class="main-heading">Image Mapper - Upload Your Own Photo</h1>
            </div>
            <div class="im-body">
                <span>Quickly view products on your own home. It's simple! Upload a photo of your house to auto detect your roof and exterior walls then begin exploring products.
                </span>

                <div class="process">
                    <img src="/img/process.svg">
                </div>

                <h4>Choose an image from your computer to upload. Don't have an image available? Most smart devices capture images that meet our photo guidelines, so take a photo with your phone or tablet and save it to your computer, and upload from there!</h4>
                <ul>
                    <li><i class="fa fa-check"></i>Take a photo of a house your house or use an existing image.</li>
                    <li><i class="fa fa-check"></i>Make sure the roof is the focal point of your image.</li>
                    <li><i class="fa fa-check"></i>Straight on photos are best. Avoid extreme angles.</li>
                       <li><i class="fa fa-check"></i>Image should have good lighting and limited obstruction of roof.</li>
                </ul>
                <div class="im-content">
                    <div class="im-step">
                        <img src="/img/pro/im-1.jpg"><p>Good Photo</p>
                    </div>
                    <div class="im-step">
                        <img src="/img/pro/im-2.jpg"><p>Bad Photo </p>
                    </div>
                
                </div>
              
                <br>
              
                <button id="upload-banner" type="button" data-csaction="openupload">BEGIN USING IMAGE MAPPER</button>
            </div>
        </div>
    </aside>
`;
        }

        connectedCallback() {
            var $ = this;
            $.classList.add("im-component");
            //this.classList.add("hide");  //default status

            var context = document.createElement('div');
            context.classList.add("im-context");
            $.draw(context);
            //console.log('this2', this.shadow);

            $.shadow.appendChild(context);

            //check attributes
            (function () {
                var arr = myClass.observedAttributes;
                for (var i = (arr.length - 1) | 0; i > -1; i = (i - 1) | 0) {
                    var tmpattr = $.getAttribute(arr[i]);
                    if (tmpattr != null) $.useAttribute(arr[i], tmpattr);
                }
            }()); 
        }

        disconnectedCallback() {
        }

        static get observedAttributes() { return ['mytitle', 'calltoaction', 'active']; }

        attributeChangedCallback(name, oldValue, newValue) {
            this.useAttribute(name, newValue);         
        }

        useAttribute(name, newValue) {
            var el;
            if (name === 'mytitle') {
                el = this.shadow.querySelector('h1');
                if (el) el.innerHTML = newValue;
            }
            else if (name === 'calltoaction') {
                el = this.shadow.querySelector('[data-csaction="openupload"]');
                if (el) {
                    el.innerHTML = newValue;
                    el.setAttribute('aria-label', newValue);
                }
            }
            else if (name === 'active') {
                if (newValue === '1') this.classList.add("active");
                else this.classList.remove("active");
            }
        }

       

    }


    if (!window.customElements.get('im-intro')) window.customElements.define('im-intro', myClass); 
}());
