//<im-wait mytitle="loading..."></im-wait>
/*
var wait = document.querySelector('im-wait');
if (wait) wait.setAttribute('active', '1');
*/
(function () {

    class myClass extends HTMLElement {
        constructor() {
            super();            
            this.shadow = this;
            //this.shadow = this.attachShadow({ mode: 'open' });
            this.head = document.head;
            this.css();
        }

        css() {
                var scss = `im-wait {
  display: none;
}
im-wait .loading-msg.lightbox > div {
  background-color: transparent;
  color: #ffffff;
  font-size: 80px;
  z-index: 999;
  max-width: 200px;
}
im-wait .loading-msg.lightbox > div .spinner-wrapper {
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -ms-flex-pack: center;
  justify-content: center;
}
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
im-wait .spinner {
  height: 100vh;
  opacity: 1;
  position: relative;
  transition: opacity linear 0.1s;
}
im-wait .spinner::before {
  animation: 2s linear infinite spinner;
  border: solid 3px #eee;
  border-bottom-color: #EF6565;
  border-radius: 50%;
  content: "";
  height: 40px;
  left: 50%;
  opacity: inherit;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  -ms-transform-origin: center;
  transform-origin: center;
  width: 40px;
  will-change: transform;
}`;

            IMWC.embedIMCss();
            IMWC.embedCss(this, scss);
        }
        
        //setup html
        draw(context) {
            context.innerHTML = `<div class="loading-msg lightbox ghost-center">
                <div>
                <div class="spinner-wrapper"><div class="spinner"></div></div>
               </div>
            </div>`;
        }

        connectedCallback() {
            var $ = this;
            $.classList.add("im-component");

            var context = document.createElement('div');
            context.classList.add("im-context");
            $.draw(context);
            //console.log('this2', this.shadow);

            $.shadow.appendChild(context);

            //check attributes
            (function () {
                var arr = myClass.observedAttributes;
                for (var i = (arr.length - 1) | 0; i > -1; i = (i - 1) | 0) {
                    var tmpattr = $.getAttribute(arr[i]);
                    if (tmpattr != null) $.useAttribute(arr[i], tmpattr);
                }
            }()); 
        }

        disconnectedCallback() {
        }

        static get observedAttributes() { return ['active']; }

        attributeChangedCallback(name, oldValue, newValue) {
            this.useAttribute(name, newValue);
        }

        useAttribute(name, newValue) {
            if (name === 'active') {
                if (newValue === '1') this.classList.add("active");
                else this.classList.remove("active");
            }
        }
        

       

    }


    if (!window.customElements.get('im-wait')) window.customElements.define('im-wait', myClass); 
   
}());
